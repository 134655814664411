import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import moment from "moment";
import { PortableText } from "@portabletext/react";

import SearchEngineOptimization from "../components/utility/SearchEngineOptimization";
import ShareButtons from "../components/utility/ShareButtons";
import BlogAuthorCard from "../components/blog/BlogAuthorCard";
import DisclosureCard from "../components/cards/DisclosureCard";
import TableOfContents from "../components/blog/TableOfContents";
import SidebarNewsletter from "../components/forms/SidebarNewsletter";
import RelatedArticles from "../components/blog/ArticlesSection";
import SupportBand from "../components/general/SupportBand";
import AboutCard from "../components/cards/AboutCardPortrait";

// import Comments from "../components/Comments";
// import SignModal from "../components/SignModal";

import sanityConnectInfo from "../../sanityConnectInfo.json";
import calcAvgReadTime from "../utils/calcAvgReadTime";
import toReadableTime from "../utils/toReadableTime";
import titleToSlug from "../utils/titleToSlug";
import components from "../utils/componentsForPortableText";

import Linka from "../components/utility/Linka";
import TipJar from "../components/utility/TipJar";
import AffiliateCard from "../components/cards/AffiliateCard";

const BlogStyles = styled.div`
  padding-left: 5%;
  padding-right: 5%;

  .blog-content-section {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }

  h2 {
    margin-top: 2rem;
  }

  h1 {
    @media (max-width: 350px) {
      font-size: 30px;
    }
  }

  .content {
    h2::before {
      display: block;
      content: " ";
      margin-top: -200px;
      height: 200px;
      visibility: hidden;
      pointer-events: none;

      @media (max-width: 1200px) {
        margin-top: -280px;
        height: 280px;
      }
    }
  }

  .published {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;

    color: var(--color3);
    margin-bottom: 2rem;

    @media (max-width: 350px) {
      display: block;
    }

    .blog-info {
      color: var(--dark-grey);
    }

    .author-image img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
    }

    .share {
      justify-self: end;
    }
  }

  .caption {
    color: var(--grey);
    font-size: 0.8rem;
  }

  .top-toc,
  .top-tip-jar,
  .top-affiliate-disclosure {
    display: none;

    @media (max-width: 1200px) {
      display: block;
      margin-top: 2rem;
    }
  }

  .top-tip-jar img {
    width: 1100px;
    max-width: 100%;
  }

  .top-affiliate-disclosure {
    @media (max-width: 1200px) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 1rem;
      align-items: center;
    }

    @media (max-width: 950px) {
      display: block;
    }

    img {
      width: 800px;
      max-width: 100%;
    }
  }

  .sidebar {
    .sidebar-newsletter,
    .sidebar-tip-jar,
    .sidebar-disclosure,
    .affiliate-card {
      margin-bottom: 1rem;
    }

    .sidebar-tip-jar img,
    .affiliate-card img {
      width: 600px;
      max-width: 100%;
    }

    .sidebar-toc {
      position: sticky;
      top: 200px;
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .bottom-content {
    margin-top: 2rem;
  }

  .bottom-about {
    margin-bottom: 1rem;
  }

  .related-articles {
    text-align: center;
  }
`;

export function Head({ data: { blog }, location, ...props }) {
  return (
    <SearchEngineOptimization
      {...props}
      meta={{
        title: blog.title,
        description: blog.description,
        image: blog.image.image.asset.gatsbyImageData,
        alt: blog.image.alt,
        ogType: "article",
        createdAt: blog._createdAt,
        updatedAt: blog._updatedAt,
      }}
      location={location}
    />
  );
}

export default function BlogPost({
  data: {
    blog,
    about,
    blogs: { nodes: blogs },
    disclosure,
    paymentGateways,
  },
  location,
}) {
  const [avgReadTime, setAvgReadTime] = useState(0);
  const [blogHeads, setBlogHeads] = useState([]);
  // const [signModalActive, setSignModalActive] = useState(false);

  useEffect(() => {
    // time to read
    setAvgReadTime(toReadableTime(calcAvgReadTime(blog.textEditor)));

    // get h2 titles
    const newBlogHeads = [];
    blog.textEditor.forEach((textSection, i) => {
      textSection._rawRichText.forEach((block) => {
        if (block.style === "h2") {
          newBlogHeads.push({
            index: i,
            text: block.children.map((blockChild) => blockChild.text).join(" "),
          });
        }
      });
    });
    setBlogHeads(newBlogHeads);
  }, [blog.textEditor]);
  return (
    <>
      <BlogStyles>
        <div className="blog-content-section">
          <div className="content">
            <h1>{blog.title}</h1>

            <div className="published">
              {blog.blogAuthor ? (
                <Linka to={`/author/${blog.blogAuthor.slug.current}/`}>
                  <span
                    className="blog-info"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "50px 1fr",
                      gap: "1rem",
                    }}
                  >
                    <span className="author-image">
                      <GatsbyImage
                        image={
                          blog.blogAuthor.image.image.asset.gatsbyImageData
                        }
                        alt={blog.blogAuthor.name}
                        className="webfeedsFeaturedVisual"
                      />
                    </span>

                    <span className="date-time">
                      {blog.blogAuthor.name} <br />
                      Updated {moment(blog._updatedAt).format("MMM DD, YYYY")}
                      {" · " + avgReadTime + " read"}
                    </span>
                  </span>
                </Linka>
              ) : (
                <span className="blog-info">
                  <span className="date-time">
                    Updated {moment(blog._updatedAt).format("MMM DD, YYYY")}
                    {" · " + avgReadTime + " read"}
                  </span>
                </span>
              )}

              <ShareButtons
                location={location}
                image={blog.image.image.asset.gatsbyImageData}
                title={blog.title}
                description={blog.description}
              />
            </div>

            {blog.image &&
              (blog.image.linkUrl ? (
                <>
                  <Linka to={blog.image.linkUrl} target="_blank">
                    <GatsbyImage
                      image={blog.image.image.asset.gatsbyImageData}
                      alt={blog.image.alt}
                    />
                  </Linka>

                  {blog.image.caption && (
                    <div className="caption">{blog.image.caption}</div>
                  )}
                </>
              ) : (
                <>
                  <GatsbyImage
                    image={blog.image.image.asset.gatsbyImageData}
                    alt={blog.image.alt}
                  />

                  {blog.image.caption && (
                    <div className="caption">{blog.image.caption}</div>
                  )}
                </>
              ))}

            <div className="top-tip-jar">
              <TipJar />
            </div>

            <div className="top-affiliate-disclosure">
              <AffiliateCard />
              <DisclosureCard disclosure={disclosure} />
            </div>

            <div className="top-toc">
              <TableOfContents headings={blogHeads.map((head) => head.text)} />
            </div>

            {blogHeads.length &&
              blog.textEditor.map((textSection, i) => {
                let currentHead = blogHeads.find((head) => head.index === i);
                if (currentHead) currentHead = currentHead.text;

                return textSection._rawRichText.find(
                  (block) => block.style === "h2" && currentHead,
                ) ? (
                  <div
                    key={textSection._key}
                    id={titleToSlug(currentHead)}
                    className="blog-section"
                  >
                    <PortableText
                      value={textSection._rawRichText}
                      projectId={sanityConnectInfo.projectId}
                      dataset={sanityConnectInfo.dataset}
                      components={components}
                    />
                  </div>
                ) : (
                  <div key={textSection._key} className="blog-section">
                    <PortableText
                      value={textSection._rawRichText}
                      projectId={sanityConnectInfo.projectId}
                      dataset={sanityConnectInfo.dataset}
                      components={components}
                    />
                  </div>
                );
              })}

            {blog.outro && (
              <div className="outro">
                {blog.outro.map((text) =>
                  text.textEditor.map((section, i) => (
                    <PortableText
                      value={section._rawRichText}
                      projectId={sanityConnectInfo.projectId}
                      dataset={sanityConnectInfo.dataset}
                      components={components}
                      key={i}
                    />
                  )),
                )}
              </div>
            )}
          </div>

          <div className="sidebar">
            <div className="sidebar-disclosure">
              <DisclosureCard disclosure={disclosure} />
            </div>

            <div className="affiliate-card">
              <AffiliateCard />
            </div>

            <div className="sidebar-tip-jar">
              <TipJar />
            </div>

            <div className="sidebar-newsletter">
              <SidebarNewsletter
                about={about}
                signup={blog.blogCategory.signup}
              />
            </div>
            <div className="sidebar-toc">
              <TableOfContents headings={blogHeads.map((head) => head.text)} />
            </div>
          </div>
        </div>

        {/*<Comments
              postId={blog.id}
              setSignModalActive={setSignModalActive}
            />*/}

        <div className="author-signup-section">
          {blog.blogAuthor && (
            <div className="bottom-about">
              <BlogAuthorCard
                about={blog.blogAuthor}
                url={`/author/${blog.blogAuthor.slug.current}/`}
              />
            </div>
          )}

          <div className="bottom-about">
            <AboutCard about={about} />
          </div>
        </div>

        <div className="bottom-support-band">
          <SupportBand support={paymentGateways} />
        </div>

        {blogs.length > 0 && (
          <div className="related-articles">
            <h2>Related Articles</h2>
            <RelatedArticles blogs={blogs} />
          </div>
        )}
      </BlogStyles>

      {/*<SignModal active={signModalActive} setActive={setSignModalActive} />*/}
    </>
  );
}

export const query = graphql`
  query ($slug: String, $tag: String, $categorySlug: String) {
    blog: sanityBlog(slug: { current: { eq: $slug } }) {
      id
      title
      _createdAt
      _updatedAt

      blogCategory {
        title
        slug {
          current
        }
        signup
      }

      tags {
        slug {
          current
        }
      }

      description
      slug {
        current
      }

      image {
        alt
        caption
        linkUrl
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }

      outro {
        textEditor {
          _rawRichText
        }
      }

      blogAuthor {
        name
        slug {
          current
        }
        caption
        description

        image {
          alt
          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }

      textEditor {
        _key
        _type
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
    }

    blogs: allSanityBlog(
      filter: {
        blogCategory: { slug: { current: { eq: $categorySlug } } }
        tags: { elemMatch: { slug: { current: { eq: $tag } } } }
        slug: { current: { ne: $slug } }
      }
      limit: 4
      sort: { fields: [_updatedAt], order: DESC }
    ) {
      nodes {
        title
        description
        slug {
          current
        }
        blogCategory {
          title
          slug {
            current
          }
        }
        tags {
          title
          slug {
            current
          }
        }

        image {
          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }

    about: sanitySection(title: { eq: "About Family I Love" }) {
      title
      textEditor {
        _key
        _type
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
    }

    disclosure: sanitySection(title: { eq: "Disclosure" }) {
      textEditor {
        _key
        _type
        _rawRichText
      }
    }

    paymentGateways: sanitySection(title: { eq: "Support The Band" }) {
      textEditor {
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
      title
    }
  }
`;
