import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Link as LinkIcon, Rss } from "@styled-icons/boxicons-regular";
import Linka from "./Linka";
import { StickyShareButtons } from "sharethis-reactjs";

const ShareButtonStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 350px) {
    display: inline-block;
  }

  .button {
    margin-right: 0.5rem;

    svg {
      color: var(--white);
      background-color: var(--black);
      border-radius: 50%;
      padding: 2px;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }

  .notify {
    &.notify-visible {
      transform: translateX(0);
      transition-timing-function: ease-out;
      transition: 0.8s;
    }

    &.notify-invisible {
      transform: translateX(-130%);
    }
  }
`;

const ShareButtons = ({
  location,
  image,
  description,
  title,
  message,
  subject,
}) => {
  const [notify, setNotify] = useState("");
  const notifyTimeout = useRef();

  const beginNotifyCountdown = () => {
    notifyTimeout.current = setTimeout(() => {
      setNotify({});
    }, 5000);
  };

  const handleClick = () => {
    navigator.clipboard.writeText(location.href);
    setNotify({ msg: "Copied to Clipboard!", color: "#028703" });
    beginNotifyCountdown();
  };

  return (
    <>
      <div
        className={`notify ${
          notify.msg ? "notify-visible" : "notify-invisible"
        }`}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: notify.color,
          color: "white",
          padding: "0 20px",
          zIndex: 1000,
        }}
      >
        <p>{notify.msg}</p>
      </div>
      <ShareButtonStyles className="share">
        <StickyShareButtons
          config={{
            alignment: "left", // alignment of buttons (left, right)
            color: "social", // set the color of buttons (social, white)
            enabled: true, // show/hide buttons (true, false)
            font_size: 16, // font size for the buttons
            hide_desktop: false, // hide buttons on desktop (true, false)
            labels: "counts", // button labels (cta, counts, null)
            language: "en", // which language to use (see LANGUAGES)
            min_count: 1000, // hide react counts less than min_count (INTEGER)
            networks: [
              // which networks to include (see SHARING NETWORKS)
              "facebook",
              "twitter",
              "pinterest",
              "linkedin",
              "reddit",
              "whatsapp",
              "messenger",
            ],
            padding: 12, // padding within buttons (INTEGER)
            radius: 4, // the corner radius on each button (INTEGER)
            show_total: false, // show/hide the total share count (true, false)
            show_mobile: true, // show/hide the buttons on mobile (true, false)
            show_toggle: true, // show/hide the toggle buttons (true, false)
            size: 48, // the size of each button (INTEGER)
            top: 400, // offset in pixels from the top of the page

            // OPTIONAL PARAMETERS

            url: location.href, // (defaults to current url)
            image: image, // (defaults to og:image or twitter:image)
            description: description, // (defaults to og:description or twitter:description)
            title: title, // (defaults to og:title or twitter:title)
            username: "@FamilyILoveBand", // (only for twitter sharing)
          }}
        />

        <span className="button link">
          <LinkIcon title="copy link" onClick={handleClick} />
        </span>

        <span className="button rss">
          <Linka title="RSS" to="/rss.xml">
            <Rss />
          </Linka>
        </span>
      </ShareButtonStyles>
    </>
  );
};

export default ShareButtons;
