import React from "react";


import AffiliateBanner from "../../images/affiliate-banner.png";

export default function DisclosureCard({ disclosure }) {
  return (
    <img
      src={AffiliateBanner}
      alt="Making purchases by clicking our affiliate links is a great way for you to support our band at no cost to you."
    />
  );
}
