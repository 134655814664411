import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import Linka from "../utility/Linka";

import SocialMedia from "../utility/SocialMedia";

const AuthorCardStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 900px) {
    display: block;
  }

  @media (max-width: 350px) {
    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }
  }

  border: 1px solid var(--grey);
  border-radius: 5px;
  padding: 10px;
  color: var(--black) !important;

  img {
    width: 100%;
    max-width: 100%;
  }

  ul {
    display: flex;
    list-style: none;
    padding-left: 0;

    svg {
      color: var(--black);
    }
  }
`;

export default function BlogAuthorCard({ about, url }) {
  return (
    <AuthorCardStyles>
      <div className="about-image">
        {about.image && (
          <GatsbyImage
            image={about.image.image.asset.gatsbyImageData}
            alt={about.image.alt}
          />
        )}
      </div>

      <div className="about-content">
        <h2>Author: {url && <Linka to={url}>{about.name}</Linka>}</h2>
        <h3>{about.caption && about.caption}</h3>
        <p>{about.description}</p>

        <SocialMedia />
      </div>
    </AuthorCardStyles>
  );
}
