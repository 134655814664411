import React from "react";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";

import sanityConnectInfo from "../../../sanityConnectInfo.json";

const DisclosureStyles = styled.div`
  p {
    font-size: 0.8rem;
  }
`;

export default function DisclosureCard({ disclosure }) {
  return (
    <DisclosureStyles>
      <PortableText
        value={disclosure.textEditor._rawRichText}
        projectId={sanityConnectInfo.projectId}
        dataset={sanityConnectInfo.dataset}
      />
    </DisclosureStyles>
  );
}
